import {
	Button,
	Flex,
	FormLabel,
	Input,
	InputGroup,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import MediaComponent from './MediaComponent';
import { useEffect, useState } from 'react';
import { IconArrowDown } from '../../../../assets/Icons';
import { AWS_S3 } from '../../../../constants/constants';

const GalleryComponent = ({
	propertyInfo,
	incorrectData,
	initialFeatureMedia,
	handleOnChange,
	currentValues,
}) => {
	const { colorMode } = useColorMode();
	const [currentGallery, setCurrentGallery] = useState(
		currentValues?.gallery ?? [],
	);
	const [deletedImages, setDeletedImages] = useState();
	const [initialGallery, setInitialGallery] = useState(propertyInfo?.gallery);
	const [featureImage, setFeatureImage] = useState(
		initialFeatureMedia ?? currentValues?.featureImage ?? '',
	);

	const selectedImages = currentGallery?.map(media => {
		return {
			url: media.url,
			fileName: media.file.name,
			isImage: media.isImage,
		};
	});

	const initialImages = initialGallery.map(media => {
		return {
			url: AWS_S3 + '/' + media.fileName,
			isImage: /\.(gif|jpg|jpeg|tiff|png)$/i.test(media.fileName),
			fileName: media.fileName,
		};
	});

	const allImages = initialImages.concat(selectedImages);

	useEffect(() => {
		if (deletedImages || currentGallery.length > 0 || featureImage) {
			const obj = {
				gallery: currentGallery.length > 0 ? currentGallery : undefined,
				deletedImages,
				featureImage: {
					...featureImage,
					featureImageName: formatFileName(featureImage.fileName),
				},
			};
			handleOnChange(obj);
		}
	}, [currentGallery, deletedImages, featureImage]);

	const formatFileName = _fileName => {
		if (_fileName) {
			return _fileName.replace(
				`projects/${propertyInfo.data.metadataId}/gallery/`,
				'',
			);
		}
	};

	const updateImages = files => {
		const newImages = files.map(file => ({
			file,
			url: URL.createObjectURL(file),
			isImage: file.type.startsWith('image/'),
		}));

		const _currentGallery = currentGallery;

		if (currentGallery) {
			newImages.forEach(newImg => {
				const index = currentGallery.findIndex(
					img => img.fileName === newImg.file.name,
				);
				if (index !== -1) {
					_currentGallery[index] = newImg;
				}
			});

			setCurrentGallery(_currentGallery.concat(newImages));
		} else {
			setCurrentGallery(newImages);
		}

		delete incorrectData[incorrectData.indexOf('gallery')];
	};

	const handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClick = () => {
		document.getElementById('fileInput').click();
	};

	const onAddMedia = event => {
		const files = Array.from(event.target.files);
		updateImages(files);
	};

	const onDropMedia = event => {
		event.preventDefault();
		event.stopPropagation();
		const files = Array.from(event.dataTransfer.files);
		updateImages(files);
	};

	const onRemoveMedia = url => {
		if (url.startsWith(AWS_S3 + '/')) {
			const _deletedImages = deletedImages ?? [];
			const fileName = url.replace(AWS_S3 + '/', '');

			_deletedImages.push(fileName);

			const filteredGallery = initialImages.filter(
				media => media.fileName !== fileName,
			);
			setDeletedImages(_deletedImages);
			setInitialGallery(filteredGallery);
		} else {
			const updatedGallery = currentGallery.filter(
				media => media.url !== url,
			);
			setCurrentGallery(updatedGallery);
		}

		const isFeaturedImageRemoved = featureImage.url === url;

		if (isFeaturedImageRemoved) {
			setFeatureImage('');
		}
	};

	return (
		<Stack gap='20px'>
			<InputGroup display='flex' flexDir='column'>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Add images
					</Text>
				</FormLabel>
				<Flex
					role='button'
					h='100px'
					alignItems='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					border={
						!incorrectData?.includes('gallery')
							? '1px dashed'
							: 'none'
					}
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					onDrop={onDropMedia}
					onDragOver={handleDragOver}
					onClick={handleClick}
					cursor='pointer'
					p='10px'
					outline={
						incorrectData?.includes('gallery')
							? '1px solid red'
							: 'none'
					}
				>
					<Input
						id='fileInput'
						type='file'
						multiple
						accept='image/*,video/*'
						style={{ display: 'none' }}
						onChange={onAddMedia}
					/>
					<Stack gap='4px'>
						<Text
							fontSize='12px'
							fontWeight='700'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							Drag and drop images or videos here or click to
							upload
						</Text>
						<Text
							fontSize='12px'
							fontWeight='400'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							Recommendation: For best viewing, use images with a
							minimun size of 1920x1080 pixels. A minimun of 5
							images is required.
						</Text>
					</Stack>
				</Flex>
			</InputGroup>
			{currentGallery && (
				<Flex wrap='wrap' gap='15.55px'>
					{allImages.map((media, index) => (
						<MediaComponent
							key={index}
							media={media}
							onRemove={() => onRemoveMedia(media.url)}
						/>
					))}
				</Flex>
			)}
			<InputGroup display='flex' flexDir='column' zIndex={100}>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Cover Image
					</Text>
				</FormLabel>
				<Menu>
					<MenuButton
						h='40px'
						w='100%'
						fontWeight='400'
						borderRadius='5px'
						textAlign='start'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						_hover={{
							bg:
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyBlack.200',
						}}
						_active={{
							border: '0.5px solid',
							borderColor:
								colorMode === 'dark'
									? 'brand.200'
									: 'brand.500',
						}}
						rightIcon={<IconArrowDown boxSize='12px' />}
						as={Button}
						isDisabled={allImages.length === 0}
						border={
							incorrectData.includes('featureImage')
								? '1px solid red'
								: 'none'
						}
					>
						<Text
							fontSize='12px'
							fontWeight='400'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							{formatFileName(featureImage?.fileName) ??
								'Select a featured image'}
						</Text>
					</MenuButton>
					<MenuList fontSize='12px' zIndex={10}>
						{allImages
							.filter(elem => elem.isImage)
							.map((elem, i) => (
								<MenuItem
									key={i}
									value={elem.fileName}
									onClick={() => setFeatureImage(elem)}
								>
									{formatFileName(elem?.fileName)}
								</MenuItem>
							))}
					</MenuList>
				</Menu>
			</InputGroup>

			{featureImage.isImage && (
				<MediaComponent
					media={featureImage}
					onRemove={() => setFeatureImage('')}
				/>
			)}
		</Stack>
	);
};

export default GalleryComponent;
