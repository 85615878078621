/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Input, List, ListItem, Box } from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';
import {} from '../../utils/GoogleMapsInfo';

const GoogleAutocomplete = ({
	selectedSuggestion,
	setSelectedSuggestion,
	incorrectData,
	initialAddressValue,
}) => {
	const inputRef = useRef(null);
	const [addressValue, setAddressValue] = useState(initialAddressValue ?? '');
	const [debouncedAddressValue] = useDebounce(addressValue, 1500);
	const [suggestions, setSuggestions] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const handleAddressChange = event => {
		setAddressValue(event.target.value);
		setSelectedSuggestion(null);
	};

	useEffect(() => {
		if (debouncedAddressValue.length >= 4 && !selectedSuggestion) {
			const getPredictions = async () => {
				const service =
					new window.google.maps.places.AutocompleteService();
				await service.getPlacePredictions(
					{ input: debouncedAddressValue, types: ['address'] },
					(predictions, status) => {
						if (
							status ===
								window.google.maps.places.PlacesServiceStatus
									.OK &&
							predictions
						) {
							setSuggestions(predictions);
						} else {
							setSuggestions([]);
						}
					},
				);
			};
			getPredictions();
		} else {
			setSuggestions([]);
		}
	}, [debouncedAddressValue]);

	const handleSelectSuggestion = async suggestion => {
		setSuggestions([]);
		setSelectedSuggestion(suggestion);
		setAddressValue(suggestion.description);
		setSelectedIndex(-1);
	};

	const handleKeyDown = event => {
		if (event.key === 'ArrowDown') {
			setSelectedIndex(prevIndex =>
				Math.min(prevIndex + 1, suggestions.length - 1),
			);
		} else if (event.key === 'ArrowUp') {
			setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0));
		} else if (event.key === 'Enter' && selectedIndex >= 0) {
			handleSelectSuggestion(suggestions[selectedIndex]);
		}
	};

	return (
		<Box position='relative'>
			<Input
				ref={inputRef}
				type='text'
				placeholder='Enter street, city, country and if possible zip code'
				value={addressValue}
				onChange={handleAddressChange}
				onKeyDown={handleKeyDown}
			/>
			{addressValue !== initialAddressValue && suggestions.length > 0 && (
				<Box
					border={
						incorrectData.includes('address')
							? '1px solid red'
							: '1px solid'
					}
					borderColor='brand.200'
					borderRadius='8px'
					position='absolute'
					top='100%'
					left='0'
					right='0'
					zIndex='100'
					overflow='hidden'
					bg='transparencyWhite.100'
					backdropFilter='blur(80px)'
				>
					<List>
						{suggestions.map((suggestion, index) => (
							<ListItem
								key={index}
								fontSize='13px'
								fontWeight='400'
								bg={
									selectedIndex === index
										? 'transparencyWhite.100'
										: 'none'
								}
								p='8px 15px'
								_hover={{ bg: 'transparencyWhite.100' }}
								onClick={() =>
									handleSelectSuggestion(suggestion)
								}
								cursor='pointer'
							>
								{suggestion.description}
							</ListItem>
						))}
					</List>
				</Box>
			)}
		</Box>
	);
};

export default GoogleAutocomplete;
