import { Flex, Text, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const FeeComponent = ({ token = 'ETH' }) => {
	const { colorMode } = useColorMode();
	const { t } = useTranslation();

	return (
		<Flex
			flexDirection='column'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			w='100%'
			lineHeight='12px'
			border='0.5px solid'
			borderColor='transparencyBlack.100'
			borderRadius='10px'
			p='20px'
			gap='10px'
		>
			<Flex justifyContent='space-between'>
				<Text fontSize='12px' fontWeight='400'>

					{t('feeComponent.estimatedFee')}
				
				</Text>
				<Text variant='colored' fontSize='12px'>
					0.0012352 {token}
				</Text>
			</Flex>
			<Flex justifyContent='space-between'>
				<Flex>
					<Text variant='gray' fontSize='12px' fontWeight='400'>
						Market
					</Text>
					<Text
						color={colorMode === 'dark' ? 'brand.250' : 'brand.550'}
						fontSize='12px'
						fontWeight='400'
					>
						-30 sec
					</Text>
				</Flex>
				<Text fontSize='14px' fontWeight='700'>
					0.0053€
				</Text>
			</Flex>
		</Flex>
	);
};

export default FeeComponent;
