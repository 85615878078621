import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import {
	Box,
	Flex,
	Image,
	Text,
	useTheme,
	useColorMode,
} from '@chakra-ui/react';
import { IconShare } from '../../../assets/Icons';

export const SliderPhotos = ({ projectImages }) => {
	const theme = useTheme();
	const { colorMode } = useColorMode();
	const [currentSlide, setCurrentSlide] = useState(1);

	const bulletColor =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	const bgBulletColor = theme.colors.transparencyWhite[800];

	return (
		<Box w={'100vw'} mx={{ base: -5, md: '-30px' }} position='relative'>
			<Swiper
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				spaceBetween={0}
				slidesPerView={1}
				pagination={{ clickable: true }}
				onSlideChange={swiper =>
					setCurrentSlide(swiper.activeIndex + 1)
				}
			>
				{projectImages.map((img, idx) => (
					<SwiperSlide key={idx}>
						<Image
							src={img}
							alt={`Slide ${idx}`}
							w='100%'
							h='375px'
							objectFit='cover'
						/>
					</SwiperSlide>
				))}

				<style>
					{`
						.swiper-pagination-bullet {
							width: 7px;
							height: 7px;
							background-color: ${bgBulletColor} ;
;
							opacity: 1 ;
							}
						.swiper-pagination-bullet-active {
							background-color: ${bulletColor} ;
						}
					`}
				</style>
			</Swiper>
			<Box
				position='absolute'
				bottom='20px'
				right='20px'
				bg='transparencyBlack.500'
				color='white'
				p='5px'
				borderRadius='3'
				zIndex={8}
			>
				<Text fontSize='10'>
					{currentSlide}/{projectImages.length}
				</Text>
			</Box>
			<Flex
				bg='transparencyWhite.800'
				w='30px'
				h='30px'
				borderRadius='50%'
				position='absolute'
				top='15px'
				right='30px'
				zIndex={9}
				align='center'
				justify='center'
			>
				<IconShare color='transparencyBlack.800' boxSize='13px' />
			</Flex>
		</Box>
	);
};

export default SliderPhotos;
