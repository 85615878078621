import { useState } from 'react';
import {
	Button,
	Flex,
	Icon,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import ModalComponent from '../../../components/ModalComponent';
import CrossBoxComponent from '../../../components/CrossBoxComponent';
import { IconArrowDown, IconSend, IconEther } from '../../../assets/Icons';
import { tokenList } from '../../../data/optionsData';
import FeeComponent from '../../../components/FeeComponent';
import { useTranslation } from 'react-i18next';

const SendTokensComponent = ({ isOpenSendModal, closeSendModal, isMobile }) => {
	const { colorMode } = useColorMode();
	const [selectedToken, setSelectedToken] = useState(tokenList[0]);
	
	const { t } = useTranslation();

	const handleItemClick = item => {
		setSelectedToken(item);
	};

	return (
		<ModalComponent
			isOpen={isOpenSendModal}
			onClose={closeSendModal}
			modalCross={false}
		>
			<>
				<Flex w='100%' justify='end'>
					<CrossBoxComponent closeModal={closeSendModal} />
				</Flex>
				<Stack
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyWhite.800'
					}
					border='0.5px solid'
					borderColor='transparencyBlack.200'
					borderRadius='12px'
					p='20px'
					w={isMobile ? 'fit-content' : '312px'}
					gap='20px'
				>
					<Text
						fontSize='20px'
						fontWeight='700'
						letterSpacing={-0.02}
						textAlign='center'
					>
						{t('walletPage.sendTokens')}
					</Text>
					<Flex w='100%' gap='12px'>
						<Stack minW='128px'>
							<Text
								fontSize='14px'
								fontWeight='700'
								letterSpacing={-0.02}
							>
								{t('walletPage.asset')}
							</Text>
							<Menu>
								<MenuButton
									w='100%'
									h='52px'
									fontSize='12px'
									fontWeight='light'
									borderRadius='5px'
									pl='5px'
									pr='10px'
									bg={
										colorMode === 'dark'
											? 'transparencyWhite.100'
											: 'transparencyBlack.100'
									}
									_hover={{
										bg:
											colorMode === 'dark'
												? 'transparencyWhite.200'
												: 'transparencyBlack.200',
									}}
									_active={{
										bg:
											colorMode === 'dark'
												? 'transparencyWhite.200'
												: 'transparencyBlack.200',
									}}
									spacing={7}
									rightIcon={
										<IconArrowDown
											boxSize='16px'
											color='transparencyWhite.500'
										/>
									}
									as={Button}
								>
									<Flex gap={1} alignItems='center'>
										<Icon as={IconEther} boxSize='25px' />
										<Stack textAlign='start'>
											<Text
												fontSize='13px'
												fontWeight='700'
												letterSpacing={-0.02}
												lineHeight='10px'
											>
												{selectedToken.abbr}
											</Text>
											<Text
												variant='gray'
												fontSize='13px'
												fontWeight='400'
												letterSpacing={-0.02}
												lineHeight='10px'
											>
												{selectedToken.token}
											</Text>
										</Stack>
									</Flex>
								</MenuButton>
								<MenuList fontSize='12px' zIndex={99}>
									{tokenList.map((elem, i) => (
										<MenuItem
											key={i}
											value={elem.token}
											onClick={() =>
												handleItemClick(elem)
											}
											gap='10px'
											fontSize='13px'
										>
											<Icon
												as={elem.icon}
												boxSize='17px'
											/>
											{elem.token}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Stack>
						<Stack>
							<Text
								fontSize='14px'
								fontWeight='700'
								letterSpacing={-0.02}
							>
								{t('walletPage.amount')}
							</Text>
							<Flex
								w='100%'
								h='52px'
								bg='transparencyWhite.100'
								align='center'
								borderRadius='5px'
								p='10px'
							>
								<Input
									variant='noStyled'
									placeholder={t('walletPage.value')}
									pl='0px'
									pr='5px'
								/>
								<Button maxH='32px' variant='main'>
									<Text
										variant='inverse'
										fontSize='12px'
										fontWeight='700'
										lineHeight='20px'
									>
										Max
									</Text>
								</Button>
							</Flex>
						</Stack>
					</Flex>

					<Stack>
						<Text
							fontSize='14px'
							fontWeight='700'
							lineHeight='20px'
						>
							{t('walletPage.addressToSend')}
						</Text>
						<Input
							variant='withBorder'
							fontSize='12px'
							placeholder={t('walletPage.placeHolder')}
							p='10px'
						/>
					</Stack>

					<Text
						fontSize='10px'
						lineHeight='15px'
						fontWeight='400'
						mt='-10px'
					>
						<Text
							as='span'
							variant='colored'
							fontSize='10px'
							lineHeight='15px'
						>
							{t('walletPage.disclaimerTitle')}
						</Text>{' '}
						{t('walletPage.disclaimerContent')}
					</Text>

					<FeeComponent token={selectedToken.abbr} />

					<Button
						variant='main'
						w='fit-content'
						alignSelf='center'
						gap='5px'
					>
						<IconSend boxSize='14px' />
						{t('walletPage.send')}
					</Button>
				</Stack>
			</>
		</ModalComponent>
	);
};

export default SendTokensComponent;
