/* eslint-disable react-hooks/exhaustive-deps */
import {
	purchasedOrPledgeOptions,
	projectStatusOptions,
	projectCategoryOptions,
	buildSubcategories,
	rentalSubcategories,
	flipSubcategories,
	propertyTypeOptions,
	postedByOptions,
} from '../../../../data/optionsData';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Stack,
	Switch,
	Text,
	Textarea,
	Tooltip,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';
import { IconPlus, IconHelp } from '../../../../assets/Icons';
import AddEquipmentModal from './AddEquipmentModal';
import EquipmentBoxComponent from './EquipmentBoxComponent';
import AddressAutocomplete from '../../../../components/GooglePlacesComponent/AddressAutocomplete';
import DateDayPickerComponent from '../../../../components/DatePickerComponent/DateDayPickerComponent';
import DateMonthPickerComponent from '../../../../components/DatePickerComponent/DateMonthPickerComponent';
import { addDaysToDate } from '../../../../utils/CalculateTime';
import { initialVoidEquipments } from '../../data';
import { getInitialEquipments } from '../../../../utils/projectDataUtils';

const getSubcategories = category => {
	switch (category) {
		case 'Build':
			return buildSubcategories;
		case 'Rental':
			return rentalSubcategories;
		case 'Flip':
			return flipSubcategories;
	}
};

const InformationComponent = ({
	initialValues,
	incorrectData,
	deleteFromIconrrectData,
	handleOnChange,
	currentValues,
}) => {
	const { colorMode } = useColorMode();

	const getCurrentEquipmentValues = () => {
		const _currentValues =
			currentValues?.equipments || initialValues?.data?.equipments;
		if (_currentValues) {
			return getInitialEquipments(_currentValues);
		} else {
			return [...initialVoidEquipments];
		}
	};

	const currentEquipments = getCurrentEquipmentValues();

	const filteredCurrentEquipments = currentEquipments.filter(
		item => item.value,
	);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialAddressValue =
		currentValues?.address ?? initialValues?.data?.address;

	const onChangeSelector = selection => {
		const obj = { ...currentValues, ...selection };
		const name = Object.keys(selection)[0];
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onChangeInput = event => {
		const { name, value, type } = event.target;
		const parsedValue = type === 'number' ? parseFloat(value) : value;
		const obj = { ...currentValues, [name]: parsedValue };
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onSelectEquipment = equipments => {
		const transformedEquipments = Object.fromEntries(
			equipments.map(({ name, value }) => [name, value]),
		);
		onClose();
		const obj = {
			...currentValues,
			equipments: transformedEquipments,
		};
		handleOnChange(obj);
	};

	const onRemoveEquipment = equipment => {
		const index = currentEquipments.findIndex(
			obj => obj.name === equipment.name,
		);
		const updatedEquipments = [...currentEquipments];

		updatedEquipments[index].value =
			typeof equipment.value === 'boolean' ? false : 0;

		const transformedEquipments = Object.fromEntries(
			updatedEquipments.map(({ name, value }) => [name, value]),
		);

		const obj = { ...currentValues, equipments: transformedEquipments };
		handleOnChange(obj);
	};

	const onSelectAddress = address => {
		const obj = { ...currentValues, ...address };
		handleOnChange(obj);
	};

	return (
		<>
			<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
				<Flex gridColumn='span 12' justifyContent='right' gap='10px'>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						KYC
					</Text>
					<Switch variant='secondary'></Switch>
				</Flex>
				<FormControl
					gridColumn='span 3'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel variant='propertyLabel'>
						Project status
					</FormLabel>
					<MenuSelectComponent
						options={projectStatusOptions}
						setTabInfo={onChangeSelector}
						fieldName='propertyStatus'
						selectedOption={
							currentValues?.propertyStatus ??
							initialValues.data.propertyStatus
						}
						incorrectData={incorrectData}
					/>
				</FormControl>
				<FormControl
					gridColumn='span 3'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel
						display='flex'
						alignItems='center'
						gap='5px'
						color='transparencyWhite.500'
					>
						<Text
							variant='colored'
							fontSize='16px'
							fontWeight='700'
							lineHeight='20px'
						>
							Purchased/Pledge
						</Text>
						<Tooltip
							placement='right'
							label={
								'Purchased: The project creator holds current ownership of the property being offered in this listing.\nPledge: The project creator is seeking to raise funds to acquire the property being listed.'
							}
							sx={{ whiteSpace: 'pre-line' }}
						>
							<span>
								<IconHelp cursor='pointer' />
							</span>
						</Tooltip>
					</FormLabel>
					<MenuSelectComponent
						options={purchasedOrPledgeOptions}
						setTabInfo={onChangeSelector}
						fieldName='purchasedOrPledge'
						selectedOption={
							currentValues?.purchasedOrPledge ??
							initialValues.data.purchasedOrPledge
						}
						incorrectData={incorrectData}
					/>
				</FormControl>
				<Stack gridColumn='span 6' w='100%'>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						lineHeight='20px'
					>
						Project type
					</Text>
					<Flex w='100%' justifyContent='space-between' gap='25px'>
						<FormControl>
							<FormLabel>
								<Text
									variant='gray'
									fontSize='13px'
									fontWeight='400'
									lineHeight='10px'
								>
									Categories
								</Text>
							</FormLabel>
							<MenuSelectComponent
								options={projectCategoryOptions}
								setTabInfo={onChangeSelector}
								fieldName='category'
								selectedOption={
									currentValues?.category ??
									initialValues.data.category
								}
								incorrectData={incorrectData}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>
								<Text
									variant='gray'
									fontSize='13px'
									fontWeight='400'
									lineHeight='10px'
								>
									Subcategories
								</Text>
							</FormLabel>
							<MenuSelectComponent
								options={getSubcategories(
									currentValues?.category ??
										initialValues.data.category,
								)}
								setTabInfo={onChangeSelector}
								fieldName='subcategory'
								isDisabled={
									!currentValues?.category &&
									!initialValues.data.category
								}
								selectedOption={
									currentValues?.subcategory ??
									initialValues.data.subcategory
								}
								incorrectData={incorrectData}
							/>
						</FormControl>
					</Flex>
				</Stack>

				<FormControl
					gridColumn='span 6'
					border={
						incorrectData?.type === 'title' ? '1px solid red' : null
					}
				>
					<FormLabel variant='propertyLabel'>Project title</FormLabel>
					<Input
						name='title'
						placeholder='Title'
						value={currentValues?.title ?? initialValues.data.title}
						onChange={onChangeInput}
						outline={
							incorrectData?.includes('title')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>
				<FormControl
					gridColumn='span 6'
					display='flex'
					flexDir='column'
					justifyContent='space-between'
				>
					<FormLabel variant='propertyLabel'>Posted by</FormLabel>
					<MenuSelectComponent
						options={postedByOptions}
						setTabInfo={onChangeSelector}
						fieldName='postedBy'
						selectedOption={
							currentValues?.postedBy ??
							initialValues.data.postedBy
						}
						incorrectData={incorrectData}
					/>
				</FormControl>

				<FormControl gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>Address</FormLabel>
					<AddressAutocomplete
						setSelectedSuggestion={onSelectAddress}
						incorrectData={incorrectData}
						initialAddressValue={initialAddressValue}
					/>
				</FormControl>
				<FormControl gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>
						Project Description
					</FormLabel>
					<Textarea
						rows='4'
						name='description'
						placeholder='Describe the project'
						value={
							currentValues?.description ??
							initialValues.data.description
						}
						onChange={onChangeInput}
						outline={
							incorrectData?.includes('description')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>
				<Box gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>Equipment</FormLabel>
					<Flex
						align='center'
						justify='center'
						border='0.5px dashed'
						borderColor={
							colorMode === 'dark' ? 'brand.200' : 'brand.500'
						}
						borderRadius='12px'
						p='15px'
						gap='15px'
						wrap='wrap'
					>
						{filteredCurrentEquipments?.map(equipment => (
							<EquipmentBoxComponent
								key={equipment.name}
								equipment={equipment}
								onRemoveEquipment={onRemoveEquipment}
							/>
						))}
						<Button
							variant='main'
							display='flex'
							gap='5px'
							onClick={onOpen}
						>
							<IconPlus boxSize='13px' />
							Add equipment
						</Button>
					</Flex>
				</Box>
				<FormControl gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>Property type</FormLabel>
					<MenuSelectComponent
						options={propertyTypeOptions}
						setTabInfo={onChangeSelector}
						fieldName='propertyType'
						selectedOption={
							currentValues?.propertyType ??
							initialValues.data.propertyType
						}
						incorrectData={incorrectData}
					/>
				</FormControl>
				<FormControl gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>Area m2</FormLabel>
					<Input
						name='areaM2'
						placeholder='Area m2'
						value={
							currentValues?.areaM2 ?? initialValues.data.areaM2
						}
						onChange={onChangeInput}
						type='number'
						outline={
							incorrectData?.includes('areaM2')
								? '1px solid red'
								: 'none'
						}
					/>
				</FormControl>

				<Box gridColumn='span 12'>
					<FormLabel
						display='flex'
						alignItems='center'
						gap='5px'
						color='transparencyWhite.500'
					>
						<Text
							variant='colored'
							fontSize='16px'
							fontWeight='700'
							lineHeight='20px'
						>
							Financing timeline
						</Text>
						<Tooltip
							placement='right'
							label='The financing timeline is the period when users can buy tokens in a project sale before they are available for trading on the open market.'
						>
							<span>
								<IconHelp cursor='pointer' />
							</span>
						</Tooltip>
					</FormLabel>

					<Flex gap='25px'>
						<Box w='100%'>
							<DateDayPickerComponent
								name='financingStart'
								setInfo={onChangeInput}
								incorrectData={incorrectData}
								minDate={new Date()}
								initialDate={
									currentValues?.financingStart ??
									initialValues.data.financingStart
								}
							/>
						</Box>
						<Box w='100%'>
							<DateDayPickerComponent
								name='financingEnd'
								setInfo={onChangeInput}
								incorrectData={incorrectData}
								minDate={addDaysToDate(1)}
								initialDate={
									currentValues?.financingEnd ??
									initialValues.data.financingEnd
								}
							/>
						</Box>
					</Flex>
				</Box>
				<Box gridColumn='span 12'>
					<FormLabel variant='propertyLabel'>
						Project timeline
					</FormLabel>
					<Flex gap='25px'>
						<Box w='100%'>
							{(currentValues?.purchasedOrPledge ||
								initialValues.data.purchasedOrPledge) ===
							'Purchased' ? (
								<DateMonthPickerComponent
									name='projectStart'
									setInfo={onChangeInput}
									incorrectData={incorrectData}
									initialDate={
										currentValues?.projectStart ??
										initialValues.data.projectStart
									}
								/>
							) : (
								<DateDayPickerComponent
									name='projectStart'
									setInfo={onChangeInput}
									jsonData
									incorrectData={incorrectData}
									initialDate={
										currentValues?.projectStart?.day ??
										initialValues.data.projectStart?.day
									}
								/>
							)}
						</Box>
						<Box w='100%'>
							<DateMonthPickerComponent
								name='projectEnd'
								setInfo={onChangeInput}
								incorrectData={incorrectData}
								initialDate={
									currentValues?.projectEnd ??
									initialValues.data.projectEnd
								}
							/>
						</Box>
					</Flex>
				</Box>
			</Grid>

			<AddEquipmentModal
				isOpen={isOpen}
				onClose={onClose}
				equipments={currentEquipments}
				onSelectEquipment={onSelectEquipment}
			/>
		</>
	);
};

export default InformationComponent;
