import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { currencyFormatter, multiplicationCalc } from '../../../utils/utils';
import {
	IconView,
	IconEdit,
	IconDots,
	IconPublish,
	IconTrash,
} from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

const PublishedInfoComponent = ({
	isMobile,
	colorBorder,
	data,
	last,
	handleDeleteProject,
	loadingAction,
}) => {
	const navigate = useNavigate();
	const { status, pricePerToken, quantityOfTokens, title, projectId } = data;

	const handleEditProject = () => {
		navigate(`/${PrivateRoutes.AddProperty}`, {
			state: { projectId },
		});
	};

	const handleViewProject = () => {
		navigate(`/${PublicRoutes.Project}/${projectId}`);
	};

	const { t } = useTranslation();
	return (
		<>
			<GridItem py={isMobile ? '15px' : '0'}>
				<Text
					fontSize={isMobile ? '12px' : '14px'}
					fontWeight={isMobile ? '600' : '500'}
				>
					{title}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(
					multiplicationCalc(quantityOfTokens, pricePerToken),
				)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{t(`adminPage.pubProperties.${status}`)}
			</Text>

			{!isMobile && (
				<Flex
					w='100%'
					justifyContent='center'
					align='center'
					p='6px'
					gap='10px'
				>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						onClick={handleEditProject}
						isDisabled={loadingAction}
					>
						<IconEdit />
						{t('adminPage.pubProperties.edit')}
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						onClick={handleViewProject}
						isDisabled={loadingAction}
					>
						<IconView />
						{t('adminPage.pubProperties.view')}
					</Button>

					<Button
						variant='main'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						isDisabled={loadingAction || status !== 'Draft'}
					>
						<IconPublish />
						{t('adminPage.pubProperties.publish')}
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
						isDisabled={loadingAction || status !== 'Draft'}
						onClick={() => handleDeleteProject(projectId)}
					>
						<IconTrash />
					</Button>
				</Flex>
			)}
			{isMobile && (
				<GridItem colSpan={isMobile ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem gap='5px' isDisabled={loadingAction}>
								<IconView />
								{t('adminPage.pubProperties.edit')}
							</MenuItem>
							<MenuItem gap='5px' isDisabled={loadingAction}>
								<IconView />
								{t('adminPage.pubProperties.view')}
							</MenuItem>
							<MenuItem
								gap='5px'
								isDisabled={loadingAction || status !== 'Draft'}
							>
								<IconPublish />
								{t('adminPage.pubProperties.publish')}
							</MenuItem>
							<MenuItem
								gap='5px'
								isDisabled={loadingAction || status !== 'Draft'}
							>
								<IconTrash />
								Delete
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isMobile ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default PublishedInfoComponent;
