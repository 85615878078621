import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorMode,
} from '@chakra-ui/react';

import { IconArrowDown } from '../assets/Icons';
import { getParamfromObjectByValue } from '../utils/utils';

const MenuSelectComponent = ({
	w = '100%',
	options,
	fieldName,
	setTabInfo,
	selectedOption,
	isDisabled = false,
	incorrectData,
}) => {
	const { colorMode } = useColorMode();

	const isObject = !(options instanceof Object && options instanceof Array);

	const _selectedOption =
		typeof selectedOption !== 'undefined' &&
		selectedOption !== null &&
		isObject
			? getParamfromObjectByValue(selectedOption, options)
			: selectedOption || undefined;

	const handleItemClick = elem => {
		setTabInfo({
			[fieldName]: elem,
		});
	};

	const handleItemClickObject = key => {
		setTabInfo({
			[fieldName]: options[key],
		});
	};

	return (
		<Menu>
			<MenuButton
				h='40px'
				w={w}
				fontWeight='200'
				borderRadius='5px'
				textAlign='start'
				isDisabled={isDisabled}
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				_hover={{
					bg:
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200',
				}}
				_active={{
					border: '0.5px solid',
					borderColor:
						colorMode === 'dark' ? 'brand.200' : 'brand.500',
				}}
				rightIcon={<IconArrowDown boxSize='12px' />}
				as={Button}
				outline={
					incorrectData?.includes(fieldName)
						? '1px solid red'
						: 'none'
				}
			>
				<Text fontSize='13px'>
					{_selectedOption ?? 'Select option'}
				</Text>
			</MenuButton>

			{!isDisabled && (
				<MenuList fontSize='12px' zIndex={10}>
					{Array.isArray(options)
						? options.map((elem, i) => (
								<MenuItem
									key={i}
									value={elem}
									onClick={() => handleItemClick(elem)}
								>
									{elem}
								</MenuItem>
							))
						: Object.keys(options).map((key, i) => (
								<MenuItem
									key={i}
									value={key}
									onClick={() => handleItemClickObject(key)}
								>
									{key}
								</MenuItem>
							))}
				</MenuList>
			)}
		</Menu>
	);
};

export default MenuSelectComponent;
