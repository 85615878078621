import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';
import { formatProjectParameters } from '../utils/projectDataUtils';

export const addProperty = async propertyInfo => {
	const formattedPropertyInfo = formatProjectParameters(propertyInfo);
	try {
		const token = encodeToken(formattedPropertyInfo);

		const formData = new FormData();
		formData.append('token', token);
		if (formattedPropertyInfo?.data?.isDraft && propertyInfo?.gallery) {
			propertyInfo.gallery.forEach(media => {
				formData.append('mediaFiles', media.file);
			});
		}

		const response = await axios.post('/project/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getProjectInfo = async projectId => {
	try {
		const response = await axios.get(`/project/one-project/${projectId}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getAllProjects = async (type = 'default') => {
	try {
		const response = await axios.get(`/project/all-projects/${type}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const deleteProject = async projectId => {
	try {
		const response = await axios.delete(`/project/delete/${projectId}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const updateProperty = async (propertyInfo, projectId) => {
	const formattedPropertyInfo = formatProjectParameters(propertyInfo);
	try {
		const token = encodeToken(formattedPropertyInfo);

		const formData = new FormData();
		formData.append('token', token);
		if (formattedPropertyInfo?.data?.isDraft && propertyInfo?.gallery) {
			propertyInfo.gallery.forEach(media => {
				formData.append('mediaFiles', media.file);
			});
		}

		const response = await axios.put(
			`/project/update/${projectId}`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
