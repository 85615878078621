import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import PropertyGallery from './components/PropertyGallery';
import Filters from './components/DiscoveryFilters/index.js';
import ModalComponent from '../../components/ModalComponent.js';
import Onboarding from './components/Onboarding/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/states/user.js';
import { getAllProjects } from '../../services/propertyService.js';

const Discovery = () => {
	const dispatch = useDispatch();
	const [projects, setProjects] = useState();
	const user = useSelector(store => store.user);
	const [showOnboarding, setShowOnboarding] = useState(
		user.isFirstTime || false,
	);

	const closeOnboardingModal = () => {
		dispatch(updateUser({ ...user, isFirstTime: false }));
		setShowOnboarding(false);
	};

	useEffect(() => {
		const getProjectsInfo = async () => {
			const { data } = await getAllProjects();
			setProjects(data);
		};
		getProjectsInfo();
	}, []);

	return (
		<Box mx='-15px'>
			<Filters projectList={projects} setProjectListFiltered={projects} />
			<PropertyGallery projects={projects} />

			<ModalComponent
				onClose={closeOnboardingModal}
				isOpen={showOnboarding}
				modalCross={false}
				closeOnOverlayClick={false}
				moreBlur
			>
				<Onboarding closeOnboardingModal={closeOnboardingModal} />
			</ModalComponent>
		</Box>
	);
};
export default Discovery;
