import { Button, Flex, useColorMode } from '@chakra-ui/react';
import { IconClose, IconPublish, IconSave } from '../../../assets/Icons';
import TabComponent from './TabComponent';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

const TabsHeaderComponent = ({
	state,
	handleUpdate,
	currentTab,
	publishLoading,
	draftLoading,
	tabChangeDisabled,
	handleSave,
	onSelectTab,
	tabs,
}) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const iconsColor = colorMode === 'dark' ? 'white' : 'black';

	return (
		<Flex justify='space-between' pb='30px'>
			<Button
				variant='secondary'
				fontSize='13px'
				gap='5px'
				onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
			>
				<IconClose boxSize='14px' />
				{t('addProperty.adminPanel.cancel')}
			</Button>

			<Flex
				h='40px'
				alignItems='center'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				borderRadius='5px'
				gap='5px'
				p='10px'
			>
				{tabs.map((tab, index) => (
					<TabComponent
						key={index}
						icon={<tab.icon boxSize='14px' color={iconsColor} />}
						title={tab.title}
						tabChangeDisabled={tabChangeDisabled}
						isActive={currentTab.title === tab.title}
						onSelectTab={() => onSelectTab(tab)}
					/>
				))}
			</Flex>
			<Flex gap='10px'>
				{state?.projectId ? (
					<Button
						variant={tabChangeDisabled ? 'mainDisabled' : 'main'}
						gap='5px'
						onClick={() => handleUpdate(true)}
						disabled={draftLoading || publishLoading}
						isLoading={publishLoading}
					>
						<IconPublish boxSize='14px' />
						{t('addProperty.adminPanel.update')}
					</Button>
				) : (
					<>
						<Button
							variant={
								tabChangeDisabled
									? 'secondaryDisabled'
									: 'secondary'
							}
							fontSize='13px '
							gap='5px'
							onClick={() => handleSave(true)}
							disabled={draftLoading || publishLoading}
							isLoading={draftLoading}
						>
							<IconSave boxSize='14px' />
							{t('addProperty.adminPanel.draft')}
						</Button>

						<Button
							variant={
								tabChangeDisabled ? 'mainDisabled' : 'main'
							}
							gap='5px'
							onClick={() => handleSave(false)}
							disabled={draftLoading || publishLoading}
							isLoading={publishLoading}
						>
							<IconPublish boxSize='14px' />
							{t('addProperty.adminPanel.publish')}
						</Button>
					</>
				)}
			</Flex>
		</Flex>
	);
};
export default TabsHeaderComponent;
