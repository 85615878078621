import { useState } from 'react';
import ModalComponent from '../../../../components/ModalComponent';
import { Image, Box, Button, Flex, Circle } from '@chakra-ui/react';
import { IconArrowDown } from '../../../../assets/Icons';
import CrossBoxComponent from '../../../../components/CrossBoxComponent';

const ModalGalleryPhoto = ({
	isOpenGalleryModal,
	setIsOpenGalleryModal,
	selectedImages,
}) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const handleImageClick = index => {
		setSelectedImageIndex(index);
	};

	const closeGallery = () => {
		setIsOpenGalleryModal(false);
	};

	const goToNextImage = () => {
		setSelectedImageIndex(
			prevIndex => (prevIndex + 1) % selectedImages.length,
		);
	};

	const goToPreviousImage = () => {
		setSelectedImageIndex(
			prevIndex =>
				(prevIndex - 1 + selectedImages.length) % selectedImages.length,
		);
	};

	return (
		<ModalComponent isOpen={isOpenGalleryModal} modalCross={false}>
			<>
				<Flex w='100%' justify='end'>
					<CrossBoxComponent closeModal={closeGallery} />
				</Flex>
				<Flex width='1080px' height='auto' borderRadius='14px'>
					<Box
						position='relative'
						textAlign='center'
						display='flex'
						justifyContent='center'
						alignItems='center'
					>
						<Image
							src={`/assets/images/houses/${selectedImages[selectedImageIndex]?.url}`}
							borderRadius='14px'
							w='1080px'
							h='500px'
						/>

						<Button
							position='absolute'
							top='50%'
							right='10px'
							transform='translateY(-50%) scale(0.8)'
							colorScheme='transparencyWhite'
							onClick={goToNextImage}
							borderRadius='50%'
							w='40px'
							h='40px'
							display='flex'
							alignItems='center'
							justifyContent='center'
						>
							<IconArrowDown
								boxSize={6}
								transform='rotate(-90deg)'
								marginRight='-3px'
								marginBottom='1px'
							/>
						</Button>

						<Button
							position='absolute'
							top='50%'
							left='10px'
							transform='translateY(-50%) scale(0.8)'
							colorScheme='transparencyWhite'
							onClick={goToPreviousImage}
							borderRadius='50%'
							w='40px'
							h='40px'
							display='flex'
							alignItems='center'
							justifyContent='center'
						>
							<IconArrowDown
								boxSize={6}
								transform='rotate(90deg)'
								marginRight='5px'
								marginBottom='3px'
							/>
						</Button>

						<Flex
							position='absolute'
							bottom='20px'
							left='50%'
							transform='translateX(-50%)'
							gap='5px'
						>
							{selectedImages.map((_, index) => (
								<Circle
									key={index}
									size='8px'
									bg={
										selectedImageIndex === index
											? 'transparencyWhite.950'
											: 'transparencyBlack.500'
									}
									transition='background-color 0.3s'
								/>
							))}
						</Flex>
					</Box>
				</Flex>

				<Flex justifyContent='center' p='20px' gap='10px'>
					{selectedImages.map((img, index) => (
						<Box
							key={img.id}
							as='button'
							onClick={() => handleImageClick(index)}
						>
							<Image
								src={`/assets/images/houses/${img.url}`}
								borderRadius='5px'
								w='100px'
								h='100px'
								opacity={
									img.id ===
									selectedImages[selectedImageIndex]?.id
										? 1
										: 0.6
								}
							/>
						</Box>
					))}
				</Flex>
			</>
		</ModalComponent>
	);
};

export default ModalGalleryPhoto;
