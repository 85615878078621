import {
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Text,
} from '@chakra-ui/react';
import TooltipComponent from '../../../../components/TooltipComponent';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';

const FormControlCustomComponent = ({
	gridColumn = 'span 4',
	formLabel,
	name,
	placeholder,
	value,
	onChange,
	tooltipLabel,
	symbol,
	options = false,
	incorrectData,
	type = 'text',
	selectedOption,
	deleteFromIconrrectData,
}) => {
	return (
		<FormControl
			gridColumn={gridColumn}
			display='flex'
			flexDir='column'
			justifyContent='space-between'
		>
			<Flex align='center' mb='8px' gap='10px'>
				<FormLabel variant='propertyLabel' m={0}>
					{formLabel}
				</FormLabel>

				<TooltipComponent label={tooltipLabel} />
			</Flex>
			{options ? (
				<MenuSelectComponent
					options={options}
					setTabInfo={onChange}
					fieldName={name}
					selectedOption={selectedOption}
					incorrectData={incorrectData}
					deleteFromIconrrectData={deleteFromIconrrectData}
				/>
			) : (
				<Flex align='center' gap='10px'>
					<InputGroup>
						<Input
							name={name}
							placeholder={placeholder}
							value={value}
							onChange={onChange}
							outline={
								incorrectData.includes(name)
									? '1px solid red'
									: 'none'
							}
							type={type}
						/>
						{symbol && symbol === 'Months' && (
							<InputRightElement w='fit-content' px='15px'>
								<Text
									fontSize='13px'
									fontWeight='400'
									letterSpacing={-0.02}
								>
									{symbol}
								</Text>
							</InputRightElement>
						)}
					</InputGroup>

					{symbol && symbol !== 'Months' && (
						<Text variant='colored'>{symbol}</Text>
					)}
				</Flex>
			)}
		</FormControl>
	);
};

export default FormControlCustomComponent;
