/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useNavigate } from 'react-router-dom';
import { projectListData } from '../../data/mockdata';
import { IconBack } from '../../assets/Icons';
import { Flex, Box, Text, Button, useMediaQuery } from '@chakra-ui/react';
import ProjectGeneralInfo from './components/ProjectGeneralInfo';
import SliderPhotos from './components/SliderPhotos';
import GalleryPhoto from './components/GalleryPhoto/GalleryPhoto';
import ProjectMoreInfo from './components/ProjectMoreInfo/ProjectMoreInfo';
import FundingTimeline from './components/FundingTimeline';
import OfferDetails from './components/OfferDetails';
import { PublicRoutes } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getProjectInfo } from '../../services/propertyService';
import LoadingComponent from '../../components/LoadingComponent';
import { AWS_S3 } from '../../constants/constants';

const ProjectDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const [isMobile] = useMediaQuery('(max-width: 992px)');
	const [isLoading, setLoading] = useState(true);
	const [projectInfo, setProjectInfo] = useState(null);

	/**
	 * A eliminar una vez esté arreglada la calculadora y timeline
	 */
	const projectSelected = projectListData.find(
		project => project.id === parseInt(id),
	);

	const projectImages = projectInfo?.gallery.map(image => {
		return AWS_S3 + '/' + image.fileName;
	});

	useEffect(() => {
		if (id) {
			const getProjectDetails = async () => {
				const { data } = await getProjectInfo(id);
				setProjectInfo(data);
				setLoading(false);
			};
			getProjectDetails();
		}
	}, []);

	return (
		<>
			{isLoading ? (
				<LoadingComponent />
			) : (
				<>
					{isMobile ? (
						<SliderPhotos projectImages={projectImages} />
					) : (
						<>
							<Flex
								justify='space-between'
								pb='15px'
								align='center'
							>
								<Button
									variant='secondary'
									onClick={() =>
										navigate(`${PublicRoutes.Home}`)
									}
								>
									<IconBack boxSize='15px' />

									<Text
										fontWeight='400'
										fontSize='13'
										letterSpacing={0.02}
										pl='5px'
									>
										{t('projectDetails.backDiscovery')}
									</Text>
								</Button>
							</Flex>
							<GalleryPhoto projectImages={projectImages} />
						</>
					)}

					<Flex
						alignItems={{ base: 'center', lg: 'start' }}
						flexDirection={{ base: 'column', lg: 'row' }}
						justifyContent='space-between'
						gap={{ base: '30px', lg: '40px', xl: '60px' }}
						pt='30px'
					>
						<Box maxW='704px' w='100%'>
							<ProjectGeneralInfo projectInfo={projectInfo} />
							<ProjectMoreInfo projectInfo={projectInfo} />
						</Box>
						<Flex
							flexDirection='column'
							gap='50px'
							align={{ base: 'center', lg: 'end' }}
						>
							<OfferDetails
								projectSelected={projectSelected}
								projectInfo={projectInfo}
							/>
							<FundingTimeline projectInfo={projectInfo} />
						</Flex>
					</Flex>
				</>
			)}
		</>
	);
};

export default ProjectDetails;
