import SingleUpdateComponent from './SingleUpdateComponent';
import { Stack } from '@chakra-ui/react';

const ProjectUpdatesTab = ({ updates }) => {
	const now = new Date();
	return (
		<Stack gap='20px'>
			{updates.map((update, index) => (
				<SingleUpdateComponent key={index} update={update} now={now} />
			))}
		</Stack>
	);
};

export default ProjectUpdatesTab;
