import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import SmallBoxBigNumberComponent from './SmallBoxBigNumberComponent';
import { useTranslation } from 'react-i18next';

const HigherROIComponent = ({ isTablet }) => {
	const { t } = useTranslation();


	return (
		<Stack w='100%' h='100%' minH='150px' p='15px 25px' gap='10px'>
			<Text
				fontSize='16px'
				fontWeight='700'
				letterSpacing={0.02}
				lineHeight='12px'
			>
				{t('adminPage.higherROI.title')}
				
			</Text>
			<SimpleGrid columns={2} spacing='20px' w='100%' h='100%'>
				<SmallBoxBigNumberComponent
					bigText='10,3%'
					smallText={t('adminPage.higherROI.rental')}
					isTablet={isTablet}
				/>
				<SmallBoxBigNumberComponent
					bigText='14%'
					smallText={t('adminPage.higherROI.flipBuild')}
					isTablet={isTablet}
				/>
			</SimpleGrid>
		</Stack>
	);
};

export default HigherROIComponent;
