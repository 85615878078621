import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import { IconArrowDown } from '../../../../assets/Icons';
import ModalComponent from '../../../../components/ModalComponent';
import { useState } from 'react';

const AddEquipmentModal = ({
	isOpen,
	onClose,
	equipments,
	onSelectEquipment,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 550px)');
	const { colorMode } = useColorMode();
	const [selectedEquipment, setSelectedEquipment] = useState(equipments[0]);
	const [quantity, setQuantity] = useState();

	const handleQuantityChange = e => {
		if (/^\d{0,2}$/.test(e.target.value)) {
			const numericValue = parseInt(e.target.value);
			if (isNaN(numericValue)) {
				setQuantity(0);
			} else {
				setQuantity(numericValue);
			}
		}
	};

	const handleClick = elem => {
		setQuantity('');
		setSelectedEquipment(elem);
	};

	const addEquipment = () => {
		const currentEquipments = [...equipments];
		const value = typeof quantity === 'number' ? quantity : true;
		const _selectedEquipment = { ...selectedEquipment, value };
		const index = equipments.findIndex(
			obj => obj.name === selectedEquipment.name,
		);
		currentEquipments[index] = _selectedEquipment;
		onSelectEquipment(currentEquipments);
		setSelectedEquipment(equipments[0]);
		setQuantity('');
	};

	return (
		<ModalComponent isOpen={isOpen} modalCross={false} onClose={onClose}>
			<Stack
				w='444px'
				maxW={isMobile ? '280px' : '444px'}
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.800'
				}
				align='center'
				borderRadius='12px'
				p='20px'
				gap='26px'
				mt='-50px'
			>
				<Text
					variant='colored'
					fontSize='24px'
					fontWeight='700'
					letterSpacing={-0.02}
					lineHeight='20px'
				>
					Equipment
				</Text>
				<Flex w='100%' justify='space-between' gap='12px'>
					<Stack w='100%'>
						<Text
							fontSize='14px'
							fontWeight='700'
							letterSpacing={-0.02}
							lineHeight='20px'
						>
							Select Equipment
						</Text>
						<Menu
							w='100%'
							placement={isMobile ? 'bottom-start' : 'bottom-end'}
						>
							<MenuButton
								variant='withoutHover'
								h='40px'
								w='100%'
								fontWeight='light'
								borderRadius='5px'
								textAlign='start'
								bg={
									colorMode === 'dark'
										? 'transparencyWhite.100'
										: 'transparencyBlack.100'
								}
								_hover={{
									bg:
										colorMode === 'dark'
											? 'transparencyWhite.200'
											: 'transparencyBlack.200',
								}}
								_active={{
									border: '0.5px solid',
									borderColor:
										colorMode === 'dark'
											? 'brand.200'
											: 'brand.500',
								}}
								rightIcon={<IconArrowDown boxSize='12px' />}
								as={Button}
							>
								<Flex align='center' gap='10px'>
									<selectedEquipment.icon boxSize='14px' />
									<Text
										fontSize='13px'
										fontWeight='400'
										letterSpacing={-0.02}
										lineHeight='20px'
									>
										{selectedEquipment.name}
									</Text>
								</Flex>
							</MenuButton>
							<MenuList
								maxH='45vh'
								overflowY='auto'
								px='10px'
								className={
									colorMode === 'dark'
										? 'custom-scrollbar-dark'
										: 'custom-scrollbar-light'
								}
							>
								{equipments.map((elem, i) => (
									<MenuItem
										key={i}
										value={elem}
										onClick={() => handleClick(elem)}
										p='5px 0px'
									>
										<Flex
											w='100%'
											h='40px'
											bg={
												colorMode === 'dark'
													? 'transparencyWhite.100'
													: 'transparencyBlack.100'
											}
											align='center'
											borderRadius='5px'
											p='10px'
											gap='10px'
											_hover={{
												bg:
													colorMode === 'dark'
														? 'transparencyWhite.200'
														: 'transparencyBlack.50',
											}}
										>
											<elem.icon boxSize='14px' />
											<Text
												fontSize='13px'
												fontWeight='400'
												letterSpacing={-0.02}
												lineHeight='20px'
											>
												{elem.name}
											</Text>
										</Flex>
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					</Stack>
					<FormControl maxW='67px'>
						<FormLabel
							fontSize='14px'
							fontWeight='700'
							letterSpacing={-0.02}
							lineHeight='20px'
							m='0'
							pb='7px'
						>
							Quantity
						</FormLabel>
						<Input
							type='number'
							variant='withBorder'
							value={quantity}
							onChange={handleQuantityChange}
							isDisabled={
								typeof selectedEquipment.value === 'boolean'
							}
						/>
					</FormControl>
				</Flex>
				<Flex w='100%' justify='space-evenly'>
					<Button
						variant='secondary'
						border='1px solid'
						borderColor={
							colorMode === 'dark' ? 'brand.200' : 'brand.500'
						}
						fontSize='13px'
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						variant='main'
						isDisabled={
							(typeof selectedEquipment.value !== 'boolean' &&
								!quantity) ||
							quantity === 0
						}
						onClick={addEquipment}
					>
						Save Changes
					</Button>
				</Flex>
			</Stack>
		</ModalComponent>
	);
};

export default AddEquipmentModal;
