import { useNavigate } from 'react-router-dom';
import {
	Box,
	Flex,
	Image,
	Progress,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { formatNumber, propertyPercentage } from '../../../utils/utils';
import { currency } from '../../../data/mockdata';
import { IconBathroom, IconRooms } from '../../../assets/Icons';
import { PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import {
	getEstimatedTotalROI,
	getTotalProjectFundraise,
} from '../../../utils/projectDataUtils';
import { calculateMonthDiff } from '../../../utils/CalculateTime';

/**
*
    @name InfoPropertyCardDiscovery
    @description This is the important information of the property component. It is the body part of the property card.
    @returns {JS.Element} InfoPropertyCard.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InfoPropertyCardDiscovery = ({ project, icon }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const navigate = useNavigate();
	const projectDetails = project.project;

	const estimatedROI = getEstimatedTotalROI(projectDetails);

	const maxProjectDurationDiff = calculateMonthDiff(
		new Date(projectDetails.projectStart?.from),
		new Date(projectDetails.projectEnd?.to),
	);

	const totalFundraise = getTotalProjectFundraise(projectDetails);
	const _funded = 400000; // Pendiente de cambiar a consulta del contrato
	const completionRate = propertyPercentage({
		funded: _funded,
		totalPrize: totalFundraise,
	});

	return (
		<Box
			w='100%'
			onClick={() =>
				navigate(`/${PublicRoutes.Project}/${projectDetails.projectId}`)
			}
		>
			<Box p='10px'>
				<Flex justifyContent='space-between'>
					<Text fontSize='14px' fontWeight='semibold'>
						{projectDetails.title}
					</Text>
					<Flex alignItems='center' gap='5px'>
						<Stack
							direction='row'
							alignItems='center'
							gap='3px'
							mr='2px'
						>
							<Image src={icon} boxSize='12px' />
							<Text fontSize='10' fontWeight='300'>
								{projectDetails.city}
							</Text>
						</Stack>
						<Stack direction='row' alignItems='center' gap='3px'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{projectDetails.equipments.room}
							</Text>

							<IconRooms
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
						<Stack direction='row' alignItems='center' gap='3px'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{projectDetails.equipments.bathroom}
							</Text>
							<IconBathroom
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
					</Flex>
				</Flex>
			</Box>

			<Box p='10px'>
				<Flex justifyContent='space-between' alignItems='end'>
					<Text variant='colored' fontSize='10px' fontWeight='400'>
						{completionRate}
						{t('infoPropertyCard.funded')}
					</Text>
					<Flex gap='2px' alignItems='flex-end'>
						<Text
							variant='colored'
							fontSize='10px'
							fontWeight='400'
							lineHeight='20px'
							marginBottom='-1px'
						>
							{currency[1].symbol}
						</Text>
						<Text
							variant='gray'
							fontSize='14px'
							fontWeight='400'
							lineHeight='20px'
						>
							{formatNumber(totalFundraise)}
						</Text>
					</Flex>
				</Flex>
				<Progress
					value={completionRate}
					borderRadius='25px'
					h='5px'
					colorScheme='brand'
				/>
				<Flex justifyContent='space-around' mt='10px'>
					<Flex
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						gap='3px'
					>
						<Text variant='gray' fontSize='12' fontWeight='400'>
							{t('infoPropertyCard.estProjectDuration')}
						</Text>
						<Text fontSize='14' fontWeight={'medium'}>
							{maxProjectDurationDiff}{' '}
							{t('infoPropertyCard.months')}
						</Text>
					</Flex>
					<Flex flexDirection='column' alignItems='center' gap='3px'>
						<Text variant='gray' fontSize='12' fontWeight='400'>
							ROI
						</Text>
						<Text
							variant='colored'
							fontSize='14'
							fontWeight={'medium'}
						>
							{estimatedROI} %
						</Text>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export default InfoPropertyCardDiscovery;
