import { LoadScript } from '@react-google-maps/api';
import GoogleAutocomplete from './GoogleAutocomplete';
import { useState } from 'react';
import { savePlaceDetails } from '../../utils/GoogleMapsInfo';

const AddressAutocomplete = ({
	setSelectedSuggestion,
	incorrectData,
	initialAddressValue,
}) => {
	const [selectedAddress, setSelectedAddress] = useState();

	const onSelectAddress = async address => {
		setSelectedAddress(address);
		const fullAddress = await savePlaceDetails(address);
		if (fullAddress) {
			const {
				city,
				country,
				postalCode,
				formattedAddress: address,
				coordinates,
			} = fullAddress;
			setSelectedSuggestion({
				city,
				country,
				postalCode,
				address,
				coordinates,
			});
		}
	};

	return (
		<LoadScript
			googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
			libraries={['places']}
		>
			<GoogleAutocomplete
				selectedSuggestion={selectedAddress}
				setSelectedSuggestion={onSelectAddress}
				incorrectData={incorrectData}
				initialAddressValue={initialAddressValue}
			/>
		</LoadScript>
	);
};

export default AddressAutocomplete;
