import { Text, Box, Flex, Progress, useColorMode } from '@chakra-ui/react';
import {
	propertyPercentage,
	propertyAvailable,
	currencyFormatter,
	formatOrDash,
} from '../../../../utils/utils';
import IncomesInfoBox from './IncomesInfoBox';
import { useTranslation } from 'react-i18next';
import { getTotalProjectFundraise } from '../../../../utils/projectDataUtils';

const ProjectIncomesInfo = ({ projectInfo }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const totalFundraise = getTotalProjectFundraise(projectInfo);
	const _funded = 400000; // Pendiente de cambiar a consulta del contrato
	const completionRate = propertyPercentage({
		funded: _funded,
		totalPrize: totalFundraise,
	});

	return (
		<Box
			minWidth='100%'
			border='0.5px solid'
			borderRadius='12px'
			borderColor={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			px='10px'
			py='15px'
			my='10px'
		>
			<Text variant={'smallColored'} textAlign='center' pb='10px'>
				{t(
					'projectDetails.offerDetails.projectIncomesInfo.totalRaised',
				)}
			</Text>
			<Flex
				justifyContent='center'
				alignItems='center'
				gap='10px'
				pb='10px'
			>
				<Text variant='colored' fontSize='20' fontWeight='700'>
					{0}
				</Text>
				<Text variant='gray' fontSize='14' fontWeight='400'>
					{t('projectDetails.offerDetails.projectIncomesInfo.of')}
				</Text>
				<Text fontSize='20' fontWeight='700'>
					{formatOrDash(totalFundraise)}
				</Text>
			</Flex>
			<Progress value={completionRate} h='8px' />
			<Flex
				fontSize='10'
				fontWeight='400'
				justifyContent='space-between'
				p='10px'
				pb='15px'
			>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{completionRate}%
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.funded',
						)}
					</Text>
				</Flex>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{formatOrDash(projectInfo?.minTicketPrice)}
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.minTicketPrice',
						)}
					</Text>
				</Flex>
				<Flex gap='1'>
					<Text variant='colored' fontSize='inherit'>
						{formatOrDash(
							currencyFormatter(
								propertyAvailable({
									funded: _funded,
									totalPrize: totalFundraise,
								}),
							),
						)}
					</Text>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.available',
						)}
					</Text>
				</Flex>
			</Flex>
			<IncomesInfoBox projectInfo={projectInfo} />
		</Box>
	);
};

export default ProjectIncomesInfo;
