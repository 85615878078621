import 'ol/ol.css';
import { Map, View, Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import { useEffect, useRef } from 'react';
import { Box, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import darkModeMarker from '../../../../../../assets/layout/darkModeMarker.png';
import lightModeMarker from '../../../../../../assets/layout/lightModeMarker.png';

const createTileLayer = (url, attributions) => {
	return new TileLayer({
		source: new XYZ({
			url,
			attributions,
		}),
	});
};

const createVectorLayer = (coordinates, marker) => {
	const iconFeature = new Feature({
		geometry: new Point(coordinates),
	});

	const iconStyle = new Style({
		image: new Icon({
			anchor: [0.5, 1],
			src: marker,
		}),
	});

	iconFeature.setStyle(iconStyle);

	const vectorSource = new VectorSource({
		features: [iconFeature],
	});

	return new VectorLayer({
		source: vectorSource,
	});
};

const MapComponent2 = ({ coordinates }) => {
	const { colorMode } = useColorMode();
	const marker = useColorModeValue(lightModeMarker, darkModeMarker);
	const mapRef = useRef();
	const lng = coordinates.long;
	const lat = coordinates.lat;

	useEffect(() => {
		const lightLayer = createTileLayer(
			'https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
			[
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				'&copy; <a href="https://carto.com/attributions">CARTO</a>',
			],
		);

		const darkLayer = createTileLayer(
			'https://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
			[
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				'&copy; <a href="https://carto.com/attributions">CARTO</a>',
			],
		);

		const dubaiCoordinates = fromLonLat([lng, lat]);

		const vectorLayer = createVectorLayer(dubaiCoordinates, marker);

		const map = new Map({
			target: mapRef.current,
			layers: [
				colorMode === 'dark' ? darkLayer : lightLayer,
				vectorLayer,
			],
			view: new View({
				center: dubaiCoordinates,
				zoom: 18,
			}),
		});

		return () => {
			if (map) {
				map.setTarget(null);
			}
		};
	}, [colorMode, marker, lat, lng]);

	return (
		<Box
			h='350px'
			ref={mapRef}
			className='map-container'
			borderRadius='25px'
		/>
	);
};

export default MapComponent2;
