// Math calc functions:

const sumCalc = (...numbers) => {
	return numbers
		.map(num => parseFloat(num))
		.reduce((total, num) => total + num, 0);
};

const subtractCalc = (...numbers) => {
	if (numbers.length === 0) return 0;
	const floatNumbers = numbers.map(num => parseFloat(num));
	return floatNumbers.reduce((total, num) => total - num);
};

const multiplicationCalc = (a, b) => {
	return a * b;
};

const divisionCalc = (a, b) => {
	return a / b;
};

// function to calculate a percentage:
const percentageCalc = (a, b) => {
	return b !== 0 ? (a / b) * 100 : '-';
};

// function to calculate percentage of property covered:
const propertyPercentage = project => {
	const percentage = parseInt((project?.funded * 100) / project?.totalPrize);
	return percentage;
};

// function to calculate the numbers of tokens:
const calcTokensNumber = project => {
	return Math.floor(project?.totalPrize / project?.tokensPrize);
};

// function to calculate the numbers of avariable tokens:
const calcAvariableTokens = project => {
	const tokensNumber = calcTokensNumber(project);
	return tokensNumber - project?.tokensPurchased;
};

// function to calculate the estimated total net profit
const estimatedTotalNetProfitCalc = (
	estimatedAnnualIncomeFromRental,
	rentalTimeline,
	netCapitalGainOnSale,
) => {
	return (
		(estimatedAnnualIncomeFromRental / 12) * rentalTimeline +
		netCapitalGainOnSale
	);
};

// function to calculate the annual investment return:
const annualInvestmentReturnCalc = (estimatedTotalROI, monthsNumber) => {
	return estimatedTotalROI * (12 / monthsNumber);
};

// functions to format dates:
function formatDateLongMonth(_date) {
	const date = typeof _date === 'string' ? new Date(_date) : _date;

	const day = date.getDate();
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		date,
	);
	const year = date.getFullYear();

	return `${day} ${month} ${year}`;
}

function formatDateShort(date) {
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
}

function formatMonthDate(date) {
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		date,
	);
	const year = date.getFullYear();

	return `${month} ${year}`;
}

function monthsSubtract(startDate, endDate) {
	const difference = endDate - startDate;
	const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30.44;
	const result = Math.floor(difference / millisecondsInMonth) + 1;
	return result > 0 ? result : '-';
}

// function to calculate what remains to be covered:
const propertyAvailable = project => {
	return project?.totalPrize - project?.funded;
};

// function to add a point every 3 digits and a comma for decimals:
const formatNumber = number => {
	const num = parseFloat(number);
	const [integerPart, decimalPart] = num.toString().split('.');
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		'.',
	);
	const formattedNumber = decimalPart
		? `${formattedIntegerPart},${decimalPart}`
		: formattedIntegerPart;
	return formattedNumber;
};

/**
 * @name currencyFormatter
 * @description Formats a given amount of money into a string based on the specified currency.
 * @param {number|string} money - The amount to format.
 * @param {string} [currency='USD'] - The currency code to format the money.
 * @param {string} [currencyDisplay] - Specifies how the currency is displayed. Options:
 * 'symbol' (e.g., $), 'code' (e.g., USD), or 'name' (e.g., US Dollar).
 * Defaults to 'symbol' if not provided.
 * @param {number} [minimumFractionDigits=0] - Minimum decimal places to show.
 * @returns {string} - The formatted currency string.
 * @author Sonia Rodríguez
 */

const currencyFormatter = (
	money,
	currency = 'USD',
	currencyDisplay,
	minimumFractionDigits = 0,
) => {
	const locales = {
		USD: 'en-US',
		EUR: 'de-DE',
		JPY: 'ja-JP',
		GBP: 'en-GB',
	};
	const locale = locales[currency] || 'en-US';

	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		currencyDisplay,
		minimumFractionDigits,
		maximumFractionDigits: 2,
	});
	return formatter.format(Number(money));
};

const formatOrDash = (value, currency) => {
	return value && !isNaN(value)
		? currencyFormatter(value, currency, 'symbol', 2)
		: '-';
};

const checkPath = (pathname, activePathnames) => {
	const rootPath = pathname.split('/')[1];
	return activePathnames.includes(rootPath);
};

const getTokenAmount = (value, decimals) => {
	return (Number(value) / Math.pow(10, decimals)).toString();
};

const getParamfromObjectByValue = (value, object) => {
	return Object.keys(object).find(key => object[key] === value);
};

const getValueByParamObject = (param, object) => {
	return object[param];
};

export {
	sumCalc,
	subtractCalc,
	multiplicationCalc,
	divisionCalc,
	percentageCalc,
	propertyPercentage,
	calcTokensNumber,
	calcAvariableTokens,
	propertyAvailable,
	estimatedTotalNetProfitCalc,
	annualInvestmentReturnCalc,
	formatDateShort,
	formatDateLongMonth,
	formatMonthDate,
	monthsSubtract,
	formatNumber,
	currencyFormatter,
	formatOrDash,
	checkPath,
	getTokenAmount,
	getParamfromObjectByValue,
	getValueByParamObject,
};
