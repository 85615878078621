/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Tab, Text, useColorMode, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { showToast } from '../../../utils/Toasts';

/**
*
    @name TabComponent
    @description Contains the logic to disable each tab and to display the error toast.
    @returns {JS.Element} TabComponent.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TabComponent = ({
	title,
	icon,
	tabChangeDisabled,
	isActive,
	onSelectTab,
}) => {
	const toast = useToast();
	const { colorMode } = useColorMode();

	const handleToast = () =>
		showToast(
			toast,
			'Save changes',
			'Please complete your edits before switching tabs.',
		);

	return (
		<Box
			position='relative'
			onClick={tabChangeDisabled ? handleToast : onSelectTab}
		>
			<Flex
				direction='row'
				align='center'
				isDisabled={!isActive}
				maxH='28px'
				fontWeight={isActive ? '500' : '400'}
				borderRadius='5px'
				gap='5px'
				p='10px 7px'
				bg={isActive ? 'transparencyWhite.100' : undefined}
				opacity={tabChangeDisabled && 0.5}
			>
				{icon}
				<Text
					fontSize='12px'
					lineHeight='20px'
					color={colorMode === 'dark' ? 'white' : 'black'}
				>
					{title}
				</Text>
			</Flex>
		</Box>
	);
};

export default TabComponent;
