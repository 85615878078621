import {
	IconAlert,
	IconBalcony,
	IconBathroom,
	IconBBQ,
	IconBuilding,
	IconGallery,
	IconGym,
	IconHelp,
	IconLibrary,
	IconParking,
	IconPool,
	IconRooms,
	IconSea,
	IconTimeline,
} from '../../assets/Icons';
import DollarIcon from '../../assets/Icons/currency/DollarIcon';

export const tabTitles = {
	Information: 'Information',
	Gallery: 'Gallery',
	FinancialDetails: 'Financial details',
	ProjectUpdates: 'Project updates',
	FAQs: 'FAQs',
	Timeline: 'Timeline',
};

export const tabs = [
	{
		title: tabTitles.Information,
		icon: IconBuilding,
	},
	{ title: tabTitles.Gallery, icon: IconGallery },
	{
		title: tabTitles.FinancialDetails,
		icon: DollarIcon,
	},
	{
		title: tabTitles.ProjectUpdates,
		icon: IconAlert,
	},
	{ title: tabTitles.FAQs, icon: IconHelp },
	{
		title: tabTitles.Timeline,
		icon: IconTimeline,
	},
];

export const initialPaymentMethodState = {
	usdt: false,
	usdc: false,
	eth: false,
};

export const initialPropertyInfo = {
	data: {},
	faqs: [],
	updates: [],
	timeline: [],
	projectMethodPayments: initialPaymentMethodState,
	gallery: [],
};

export const availablePaymentMethods = [
	{
		label: 'USDT',
		value: 'usdt',
	},
	{
		label: 'USDC',
		value: 'usdc',
	},
	{
		label: 'ETH',
		value: 'eth',
	},
];

export const initialVoidEquipments = [
	{ name: 'room', icon: IconRooms, value: 0 },
	{ name: 'bathroom', icon: IconBathroom, value: 0 },
	{ name: 'balcony', icon: IconBalcony, value: false },
	{ name: 'parking', icon: IconParking, value: 0 },
	{ name: 'barbacue', icon: IconBBQ, value: false },
	{ name: 'gym', icon: IconGym, value: false },
	{ name: 'library', icon: IconLibrary, value: false },
	{ name: 'pool', icon: IconPool, value: false },
	{ name: 'seaView', icon: IconSea, value: false },
];
